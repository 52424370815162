<template>
  <div class="row">
    <!-- Start Single Service  -->

    <v-col lg="12" md="12" sm="12" cols="12"> </v-col>
    <v-col
      lg="2"
      md="4"
      sm="6"
      cols="12"
      class="mt_xl--30 mt_lg--20 mt_sm--2p0 mt_md--20 withoutBorder"
      v-for="(service, i) in serviceContent"
      :key="i"
      :style="
        service.id < 6
          ? 'border-right: 0.1px solid #757575;'
          : 'border-right: 0px solid #757575;'
      "
    >
      <div class="single-service large-size text-left">
        <div class="service">
          <div style="min-height: 65px">
            <!-- <div> -->
            <a :href="service.to" target="_blank">
              <img width="180" :src="service.src" alt="Corporate Images"
            /></a>
          </div>
          <div class="content mb_sm--10">
            <!-- <h3 class="heading-title">{{ service.title }}</h3> -->
            <p>{{ service.address }}</p>
            <p>{{ service.number }}</p>
            <p>{{ service.phone }}</p>
            <a :href="service.to" target="_blank"
              ><p>{{ service.site }}</p></a
            >
          </div>
        </div>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </div>
</template>

<script>
import feather from "feather-icons";
export default {
  data() {
    return {
      serviceContent: [
        {
          id: 1,
          src: require("../../assets/images/service/hygeia_logo.svg"),
          address: "Ερυθρού Σταυρού 4 & Κηφισίας",
          number: "151 23 Μαρούσι, Αθήνα",
          phone: "210 68 67 000",
          site: "www.hygeia.gr",
          to: "https://www.hygeia.gr",
        },
        {
          id: 2,
          src: require("../../assets/images/service/metropolitan_hospital_logo.svg"),
          address: "Εθν. Μακαρίου 9 & Ελ. Βενιζέλου 1",
          number: "18547 Ν. Φάληρο, Αθήνα",
          phone: "210 48 09 000",
          site: "www.metropolitan-hospital.gr",
          to: "https://www.metropolitan-hospital.gr",
        },
        {
          id: 3,
          src: require("../../assets/images/service/mitera_logo.svg"),
          address: "Ερυθρού Σταυρού 6",
          number: "15123, Μαρούσι, Αθήνα",
          phone: "210 68 69 000",
          site: "www.mitera.gr",
          to: "https://www.mitera.gr",
        },
        {
          id: 4,
          src: require("../../assets/images/service/metropolitan_general_logo.svg"),
          address: "Λεωφόρος Μεσογείων 264",
          number: "15562 Χολαργός, Αθήνα",
          phone: "210 65 02 000",
          site: "www.metropolitan-general.gr",
          to: "https://www.metropolitan-general.gr",
        },
        {
          id: 5,
          src: require("../../assets/images/service/litw.png"),
          address: "Μουσών 7 - 13",
          number: "11524 Aθήνα",
          phone: "210 69 02 000",
          site: "www.leto.gr",
          to: "https://www.leto.gr",
        },
        {
          id: 6,
          src: require("../../assets/images/service/creta_logo.svg"),
          address: "Μίνωος 63",
          number: "71304 Ηράκλειο Κρήτης",
          phone: "2810 37 38 00",
          site: "www.cic.gr",
          to: "https://www.cic.gr",
        },
      ],
    };
  },

  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.v-application p,
body p {
  color: black;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 0px;
}
.boldParagraph {
  font-weight: bold;
}
.theme--light.v-image {
  float: left;
}

@media only screen and (max-width: 600px) {
  .withoutBorder {
    border-right: 0px solid #757575 !important;
  }
}
</style>
