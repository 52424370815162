<template>
  <v-row>
    <v-col
      xl="3"
      lg="3"
      md="3"
      sm="6"
      cols="12"
      class="mt--30"
      v-for="(service, i) in serviceContent"
      :key="i"
    >
      <div class="standard-service upOf">
        <div class="thumbnai">
          <router-link to="/privileges">
            <v-img
              v-on:click="$store.state.selectedTab = service.bonusCard"
              class="mb--50 service-card"
              :src="service.src"
              alt="Corporate Images"
          /></router-link>
        </div>
        <!-- <div class="content ml--30 mt--30"> -->
        <div class="mt--30 service-description">
          <h3 :class="service.color">
            {{ service.title }}
          </h3>
          <p style="font-size: 18px">
            {{ service.desc }}
          </p>
          <!-- <router-link class="btn-transparent rn-btn-dark" to="/bonus-card"
            ><span class="text" style="font-family: 'PFBagueSansPro-Bold';"
              ><a
                v-on:click="
                  $store.state.selectedInformation = service.bonusCard
                "
                >Περισσότερα <span style="font-size: 16px">>></span></a
              ></span
            ></router-link
          > -->
          <router-link class="btn-transparent rn-btn-dark" to="/privileges"
            ><span class="text" style="font-family: 'PFBagueSansPro-Bold'"
              ><a v-on:click="$store.state.selectedTab = service.bonusCard"
                >Περισσότερα <span style="font-size: 16px">>></span></a
              ></span
            ></router-link
          >
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      serviceContent: [
        {
          src: require("../../assets/images/home/white.png"),
          title: "Health_e Bonus White",
          color: "colorBlack",
          bonusCard: "white",
          desc: `Αφού κατεβάσετε την εφαρμογή Health_e Bonus Card γίνεστε, αυτόματα κάτοχος της Health_e Bonus White. 
          Με την πρώτη κιόλας επίσκεψη παρέχει:
           20% έκπτωση σε όλες τις υπηρεσίες των HealthSpot, 15% έκπτωση στα Check up, 10% σε όλες τις υπηρεσίες του ομίλου HHG.`,
        },
        {
          src: require("../../assets/images/home/blue.png"),
          title: "Health_e Bonus Blue",
          color: "colorBlue",
          bonusCard: "blue",
          desc: `Όταν μαζέψετε 1000 πόντους αλλάζετε αυτομάτως βαθμίδα και αποκτάτε την Health_e Bonus Blue, η οποία σας παρέχει 
          ακόμη περισσότερα προνόμια, εκπτώσεις και μοναδικές προσφορές από τον μεγαλύτερο ιδιωτικό όμιλο παροχής υπηρεσιών υγείας στη χώρα μας, HHG.`,
        },
        {
          src: require("../../assets/images/home/silver.png"),
          title: "Health_e Bonus Silver",
          color: "colorSilver",
          bonusCard: "silver",
          desc: `Όταν μαζέψετε 8000 πόντους αλλάζετε αυτομάτως βαθμίδα και αποκτάτε την Health_e Bonus Silver, η οποία σας παρέχει ακόμη περισσότερα προνόμια
          , εκπτώσεις και μοναδικές προσφορές από τον μεγαλύτερο ιδιωτικό όμιλο παροχής υπηρεσιών υγείας στη χώρα μας, HHG.`,
        },
        {
          src: require("../../assets/images/home/gold.png"),
          title: "Health_e Bonus Gold",
          color: "colorGold",
          bonusCard: "gold",
          desc: `Όταν μαζέψετε 40000 πόντους αλλάζετε αυτομάτως βαθμίδα και αποκτάτε την Health_e Bonus Gold, η οποία σας παρέχει ακόμη περισσότερα προνόμια,
           εκπτώσεις και μοναδικές προσφορές από τον μεγαλύτερο ιδιωτικό όμιλο παροχής υπηρεσιών υγείας στη χώρα μας, HHG.`,
        },
      ],
    };
  },
};
</script>
<style scoped>
.colorBlue {
  line-height: 0.25;
  color: #1c4081 !important;
  font-family: PFBagueSansPro-Bold;
  font-weight: bold;
}
.colorSilver {
  line-height: 0.25;
  color: #939094 !important;
  font-family: PFBagueSansPro-Bold;
  font-weight: bold;
}
.colorGold {
  line-height: 0.25;
  color: #c99813 !important;
  font-family: PFBagueSansPro-Bold;
  font-weight: bold;
}
.colorBlack {
  line-height: 0.25;
  color: rgb(39, 34, 34) !important;
  font-weight: bold;
}
h3 {
  font-family: "PFBagueSansPro-Bold";
}
p {
  font-size: 16px;
  /* font-family: "PFBagueSansPro-Light"; */
}
a {
  line-height: 0;
}
.service-card {
  width: 97%;
  margin-right: 3%;
}
.service-card:hover {
  width: 100%;
  margin-right: 0px;
}
@media only screen and (min-width: 958px) and (max-width: 1199px) {
  h3 {
    font-size: 16px;
  }
}
@media only screen and (min-width: 960px) {
  .upOf {
    margin-top: -150px;
  }
  .row {
    margin-top: 100px;
  }
}
</style>
