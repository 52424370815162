<template>
  <div class="row mt--30 d-flex justify-space-around">
    <!-- Start Single Service  -->

    <!-- <v-col lg="12" md="12" sm="12" cols="12" class="d-flex flex-justify-center"> </v-col> -->
    <v-col
      lg="4"
      md="4"
      sm="6"
      cols="12"
      class="pl--40 pr--40  pl_sm--10 pr_sm--10"
      v-for="(service, i) in serviceContent"
      :key="i"
    >
      <div class="single-service large-size text-center">
        <div class="service d-flex flex-column">
          <div class="center">
            <v-img :src="service.src" alt="Corporate Images">
              <div v-if="service.id === 2">
                <v-list-item
                  href="https://apps.apple.com/gr/app/health-e-bonus-card/id1589042859"
                  class="mobile-store-link appstore"
                  target="_blank"
                ></v-list-item>
                <v-list-item
                  href="https://play.google.com/store/apps/details?id=com.roadcube.hellenichealthcaregroup&hl=el&gl=US"
                  class="mobile-store-link playstore"
                  target="_blank"
                ></v-list-item>
              </div>
            </v-img>
          </div>
          <div class="content mb_sm--10">
            <h3 class="heading-title">{{ service.title }}</h3>
            <p :class="window.width < 600 ? 'text-left' : ''">
              <span v-html="service.desc"></span>
            </p>
            <!-- <div v-if="service.id === 3" class="text-left">
              <ul>
                <li>λογαριασμό e-mail</li>
                <li>αριθμό κινητού τηλεφώνου</li>
                <li>ΑΜΚΑ</li>
              </ul>
            </div> -->
          </div>
        </div>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </div>
</template>

<script>
import feather from "feather-icons";
export default {
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
      serviceContent: [
        {
          id: 1,
          src: require("../../assets/images/home/cross.png"),
          title: "",
          desc: `Μπορείτε να την αποκτήσετε σε μία επίσκεψή σας σε ένα από τα θεραπευτήρια ή τα διαγνωστικά κέντρα του Ομίλου, ζητώντας την ενεργοποίηση της ψηφιακής κάρτας υγείας από το ταμείο.`,
        },
        {
          id: 2,
          src: require("../../assets/images/home/apple.png"),
          title: "",
          desc: `Kατεβάστε την εφαρμογή Health_e Bonus Card από το App Store για συσκευές iOS και το Google Play για συσκευές Android.  
              <a href="https://www.healthebonus.gr" target="_blank">www.healthebonus.gr</a>
`,
        },
        {
          id: 3,
          src: require("../../assets/images/home/heart.png"),
          title: "",
          desc: `Η διαδικασία εγγραφής είναι απλή και σύντομη και ολοκληρώνεται οnline από κινητή ή σταθερή συσκευή. Χρειάζεται να έχετε: λογαριασμό e-mail, αριθμό κινητού τηλεφώνου και ΑΜΚΑ.
`,
        },
      ],
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
};
</script>
<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

p {
  font-family: "PFBagueSansPro-Light";
}

.mobile-store-link {
  padding: 23%;
  height: 66%;
  position: absolute;
}
.mobile-store-link.appstore {
  left: 0%;
  bottom: 15%;
}

.mobile-store-link.playstore {
  right: 0%;
  bottom: 15%;
}
</style>
