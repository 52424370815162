<template>
  <div>
    <VueSlickCarousel
      v-bind="settings"
      class="slider-activation rn-slick-dot dot-light"
    >
      <div
        class="slide slide-style-2 slider-box-content without-overlay d-flex align-center bg_image"
        data-black-overlay="1"
        v-for="(slider, i) in sliderContent"
        :key="i"
        :style="{ backgroundImage: 'url(' + slider.src + ')' }"
      >
        <v-container>
          <v-row>
            <v-col class="inner" :class="slider.text_position">
              <h2
                class="description"
                style="font-family: PFBagueSansPro-Bold !important; line-height: 1;"
              >
                {{ slider.title }}
              </h2>
              <p
                class="description"
                style="font-family: PFBagueSansPro-Regular !important; line-height: 1;"
              >
                {{ slider.desc }}
              </p>
              <!-- <div>
                <p
                  class="description2"
                  style="font-family: PFBagueSansPro-Bold !important; line-height: 1;"
                >
                  {{ slider.desc1 }}
                </p>
              </div> -->
              <div class="d-flex flex-row" v-if="user">
                <div class="mr--30 mt--40 ml_sm--0">
                  <p class="description1">
                    {{ user.user_points_info&&user.user_points_info.current_package.title.el }},
                    {{ user.total_points }} πόντοι
                  </p>
                </div>
              </div>
              <div class="d-flex flex-row" v-if="user === null">
                <!-- <div class="slide-btn mr--30"> -->
                <div class="mr--30">
                  <Login title="Σύνδεση" icon="mdi-plus" />
                </div>
                <div>
                  <Register title="Εγγραφή" icon="mdi-plus" />
                </div>
              </div>
            </v-col>
            <!-- <v-col class="inner" :class="slider.text_position">
          
          </v-col> -->
          </v-row>
        </v-container>
      </div>
      <!-- End Single Slide  -->
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import Login from "@/views/Login";
import Register from "@/views/Register";
import axios from "axios";
import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
  components: { VueSlickCarousel, Login, Register },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(["token"]),
  },
  async created() {
    await this.getUser();
  },
  methods: {
    ...mapActions(["getUser"]),
    ...mapActions(["getToken"]),
  },
  data() {
    return {
      sliderContent: [
        {
          text_position: "text-left",
          src: require("../../assets/images/bg/bg-image-6.jpg"),
          title: "Η ψηφιακή κάρτα προνομίων υγείας",
          desc: `από το Hellenic Healthcare Group`,
          desc1: `Είμαστε ο μεγαλύτερος ιδιωτικός όμιλος υπηρεσιών υγείας στην Ελλάδα. 
          Στο HHG, με έξι κορυφαία θεραπευτήρια και τέσσερα διαγνωστικά κέντρα παρέχουμε ολοκληρωμένη 
          ιατρική περίθαλψη για κάθε θέμα υγείας, από τη γέννηση έως την τρίτη ηλικία, 24 ώρες το 24ωρο, 365 ημέρες το χρόνο.`,
        },
      ],
      settings: {
        fade: true,
        dots: true,
        arrows: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        margin: 20,
      },
    };
  },
};
</script>

<style lang="scss">
.slick-slide {
  img {
    display: block;
    width: 100%;
  }
}
// .v-btn {
//   text-transform: lowercase;
// }

.theme--light.v-image {
  float: right;
}
.alignCenter {
  height: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 8px !important;
  font-weight: bold;
  // border: 2px dashed #f69c55;
}
</style>
<style scoped>
.slide.slide-style-2 .inner h2.description {
  color: #ffff;
  font-size: 65px;
  line-height: 1;
  font-family: PFBagueSansPro-Bold !important;
}
p.description {
  font-family: PFBagueSansPro-Regular !important;
  font-size: 40px !important;
  line-height: 1 !important;
}
p.description2 {
  color: #ffff;
  width: 50%;
  font-family: PFBagueSansPro-Bold !important;
  font-size: 18px !important;
}
p.description1 {
  font-size: 24px !important;
  color: #ffff;
  border: 1px;
  border-style: solid;
  border-color: #ffff;
  padding: 18px !important;
  border-radius: 100px;
}
@media only screen and (max-width: 769px) {
  h2 {
    font-size: 27px !important;
    text-align: left;
    margin-bottom: 0px;
    font-family: PFBagueSansPro-Bold !important;
  }
  p.description {
    font-size: 20px !important;
    text-align: left;
    font-family: PFBagueSansPro-Regular !important;
  }
  p.description1 {
    font-size: 14px !important;
    text-align: left;
    color: #ffff;
    border: 1px;
    border-style: solid;
    border-color: #ffff;
    padding: 18px !important;
    border-radius: 100px;
  }
  p.description2 {
    color: #ffff;
    text-align: left;
    width: 100%;
    font-family: PFBagueSansPro-Bold !important;
    font-size: 14px !important;
  }
}
@media only screen and (min-width: 770px) and (max-width: 1199px) {
  h2 {
    font-size: 55px !important;
    font-family: PFBagueSansPro-Bold !important;
  }
  p.description {
    /* font-size: 30px !important; */
    font-family: PFBagueSansPro-Regular !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1450px) {
  p.description {
    /* font-size: 35px !important; */
    font-family: PFBagueSansPro-Regular !important;
  }
}

@media only screen and (min-width: 1200px) {
  .slick-slide.slick-current .slide.slide-style-2 .inner p.description {
    /* margin-top: 25px; */
    margin-bottom: 15px;
  }
}
</style>
