<template>
  <div class="row">
    <!-- Start Single Service  -->

    <v-col
      xl="8"
      lg="8"
      md="7"
      sm="7"
      cols="12"
      class="mt--25 mb--10 mt_sm--0 mt_md--0"
    >
      <!-- <h2>Βαθμίδες και προνόμια</h2>
      <p style="line-height: 1.5">Με την κάρτα Health_e Bonus απολαμβάνετε ξεχωριστά προνόμια σε όλα τα θεραπευτήρια
      και διαγνωστικά κέντρα του Ομίλου. Με κάθε σας συναλλαγή, μαζεύετε πόντους, Health_e Bonus
      ανεβαίνετε βαθμίδα, και ανταμείβεστε για την εμπιστοσύνη σας στην οικογένεια HHG</p> -->
      <h2>
        Αποκτήστε δωρεάν την ψηφιακή κάρτα υγείας του Hellenic Healthcare Group.
      </h2>
      <div style="font-size: 20px; padding-bottom: 25px;">
       <p>Κατεβάστε την εφαρμογή <b>Health_e Bonus Card</b> και απολαύστε μια σειρά από αμέτρητα προνόμια πρωτοβάθμιας και δευτεροβάθμιας ιατρικής περίθαλψης για καλύτερη υγεία και καλύτερη ζωή. 
</p> <p>
Το Hellenic Healthcare Group,  ο μεγαλύτερος ιδιωτικός Όμιλος υπηρεσιών υγείας στην Ελλάδα, σας επιβραβεύει για την εμπιστοσύνη σας και σας καλωσορίζει σε ένα premium δίκτυο υγείας μέσα από την εφαρμογή Health_e Bonus Card. Με κάθε σας συναλλαγή (π.χ. επίσκεψη γιατρού, εξετάσεις, νοσηλεία) στα Θεραπευτήρια Υγεία, Metropolitan Hospital, Μητέρα, Metropolitan General, Λητώ, Creta InterClinic, στα Διαγνωστικά Κέντρα HealthSpot, στο Υγεία IVF και στο εργαστήριο ALab, μαζεύετε πόντους, ανεβαίνετε βαθμίδα και ανταμείβεστε για την επιλογή σας. 
</p> <p>
Η κάρτα Health_e Bonus σας δίνει τη δυνατότητα να απολαμβάνετε ξεχωριστά προνόμια με ειδικές παροχές και μοναδικές προσφορές στα Θεραπευτήρια, τα Διαγνωστικά Κέντρα και το εργαστήριο ALab του Ομίλου HHG. 
</p> <p>
Γραμμή εξυπηρέτησης για τους κατόχους της Health_e Bonus Card: <b>210 300 66 34</b></p> 
      </div>
      <v-row>
        <v-col md="5" lg="4" xl="3" cols="6">
          <router-link to="/privileges">
            <img
              :src="pictureHover"
              @mouseover="hover = true"
              @mouseleave="hover = false"
            />
          </router-link>
        </v-col>
        <v-col md="5" lg="4" xl="3" cols="6">
          <router-link to="/rewards">
            <img
              :src="pictureHover1"
              @mouseover="hover1 = true"
              @mouseleave="hover1 = false"
            />
          </router-link>
        </v-col>
      </v-row>
    </v-col>

    <v-col
      xl="3"
      lg="3"
      md="4"
      sm="4"
      cols="12"
      class="mt--60 mt_sm--5 mt_md--60 d-none d-sm-block"
    >
      <div class="d-flex flex-row">
        <v-img
          contain
          alt=""
          src="../../assets/images/bg/phone.png"
          position="absolute"
        >
        <v-list-item href="https://apps.apple.com/gr/app/health-e-bonus-card/id1589042859" class="mobile-store-link appstore" target="_blank"></v-list-item >
        <v-list-item href="https://play.google.com/store/apps/details?id=com.roadcube.hellenichealthcaregroup&hl=el&gl=US" class="mobile-store-link playstore" target="_blank"></v-list-item>
        </v-img>
        <!-- <div style="padding:20px; background-color: red;"></div> -->
      </div>
    </v-col>
    <v-col xl="1" lg="1" md="1" sm="1" cols="12"> </v-col>
    <!-- End Single Service  -->
  </div>
</template>

<script>
import feather from "feather-icons";
export default {
  data() {
    return {
      pictureStatic: require("../../assets/images/home/butt1_black.png"),
      pictureStatic1: require("../../assets/images/home/butt2_black.png"),
      pictureGif: require("../../assets/images/home/butt1_red.png"),
      pictureGif1: require("../../assets/images/home/butt2_red.png"),
      hover: false,
      hover1: false,
    };
  },
  computed: {
    pictureHover() {
      if (this.hover == true) {
        return this.pictureGif;
      } else {
        return this.pictureStatic;
      }
    },
    pictureHover1() {
      if (this.hover1 == true) {
        return this.pictureGif1;
      } else {
        return this.pictureStatic1;
      }
    },
  },
};
</script>
<style scoped>
h2 {
  font-family: "PFBagueSansPro-Bold";
}
p {
  font-family: "PFBagueSansPro-Light";
}

@media only screen and (max-width: 767px) {
  .theme--light.v-image {
    overflow: visible;
    margin-top: -200px;
    margin-left: 58px;
    float: right;
  }
  .v-responsive {
    position: relative;
    overflow: hidden;
    flex: 1 0 auto;
    max-width: 128%;
    display: flex;
  }
}

h2 {
  font-family: "PFBagueSansPro-Bold";
  font-size: 40px !important;
}
.backgroung-grey {
  background-color: #e6e6e7;
}
/* @media only screen and (max-width: 1630px) {
  h2 {
    font-size: 40px !important;
  }
}
@media only screen and (max-width: 1350px) {
  h2 {
    font-size: 35px !important;
  }
} */
@media only screen and (max-width: 769px) {
  h2 {
    font-size: 22px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .theme--light.v-image {
    overflow: visible;
    margin-top: -347px;
    margin-left: 58px;
    float: right;
  }
  .v-responsive {
    position: relative;
    overflow: hidden;
    flex: 1 0 auto;
    max-width: 128%;
    display: flex;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .theme--light.v-image {
    overflow: visible;
    margin-top: -506px;
    margin-left: 43px;
    float: right;
  }
  .v-responsive {
    position: relative;
    overflow: hidden;
    flex: 1 0 auto;
    max-width: 115%;
    display: flex;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1263px) {
  .theme--light.v-image {
    overflow: visible;
    margin-top: -513px;
    margin-left: 131px;
    float: right;
  }
  .v-responsive {
    position: relative;
    overflow: hidden;
    flex: 1 0 auto;
    max-width: 100%;
    display: flex;
  }
}

@media only screen and (min-width: 1264px) and (max-width: 1550px) {
  @keyframes phone-image-animation {
    from {
      margin-top: 0px;
    }
    to {
      margin-top: -520px;
    }
  }
  .theme--light.v-image {
    overflow: visible;
    animation: phone-image-animation 1.5s;
    animation-fill-mode: forwards;
    margin-left: 28px;
    float: right;
    transform: translateY(100px);
  }
  .v-responsive {
    position: relative;
    overflow: hidden;
    flex: 1 0 auto;
    max-width: 133%;
    display: flex;
  }
}

@media only screen and (min-width: 1551px) {
  @keyframes phone-image-animation {
    from {
      margin-top: 0px;
    }
    to {
      margin-top: -520px;
    }
  }
  .theme--light.v-image {
    overflow: visible;
    animation: phone-image-animation 2s;
    animation-fill-mode: forwards;
    margin-left: 71px;
    float: right;
    transform: translateY(100px);
  }
  .v-responsive {
    position: relative;
    overflow: hidden;
    flex: 1 0 auto;
    max-width: 110%;
    display: flex;
  }
}
.mobile-store-link {
  padding: 5%; 
  height: 5%;
  position: absolute;
  width: 27%;transform: rotate(5deg);
}
.mobile-store-link.appstore{
  left: 33%;
  bottom: 30%;
}

.mobile-store-link.playstore{
  left: 32%;
  bottom: 23%;
}

</style>
