<template>
  <div id="home">
    <!-- Start Header Area -->
    <BrandChooseWindow />
    <HeaderTwo />
    <!-- End Header Area -->

    <!-- Start Slider Area -->
    <SliderFour />
    <!-- End Slider Area -->

    <!-- Start Service Area  -->
    <div id="service" class="rn-service-area ptb--30">
      <div class="container">
        <ServiceSix />
      </div>
    </div>
    <!-- Start Service Area  -->

    <!-- Start Service Area  -->
    <div class="rn-service-area pb--40 pb_sm--20 backgroung-grey">
      <div class="container">
        <ServiceFive />
      </div>
    </div>
    <!-- Start Service Area  -->

    <!-- Start Featured Area  -->
    <div id="about" class="rn-featured-service-area pt--50 pt_sm--20 pb--50 bg_color--5">
      <v-container>
        <h2 class="mb--0">Πώς να αποκτήσετε τη δωρεάν Health_e Bonus Card</h2>
        <v-row>
          <v-col lg="12" md="12" cols="12">
            <ServiceFour />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Featured Area  -->

     <!-- Start Featured Area  -->
    <div id="about" class="rn-featured-service-area pt--20 pt_sm--0 pb--20 bg_color--5">
      <v-container>
        <h2>Πού θα μας βρείτε    </h2>
      </v-container>
    </div>
    <!-- End Featured Area  -->

    <!-- Start Contact Area  -->
      <div class="contact-form--1">
        <!-- <Contact class="class-z-index"></Contact> -->
        <GoogleMaps class="class-z-index">
                    </GoogleMaps>
          <!-- <Contact>
            <img
              slot="contact-img"
              alt="health_e"
            />
          </Contact> -->
      </div>
    <!-- End Contact Area  -->
    <div id="contact" class="rn-featured-service-area pt--40 pb--40">
      <v-container>
        <v-row>
          <v-col lg="12" md="12" cols="12">
            <ServiceContact />
          </v-col>
        </v-row>
        <v-row class="mt_md--20 mt_sm--20">
          <v-col lg="12" md="12" cols="12">
            <ServiceContactPlus />
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Start Footer Area  -->
    <Footer />
    <!-- End Footer Area  -->
  </div>
</template>
<script>
  import HeaderTwo from "../../components/header/HeaderOnePage";
  import axios from 'axios';
  import { mapMutations, mapActions, mapGetters } from "vuex";
  import SliderFour from "../../components/slider/SliderFour";
  import ServiceFour from "../../components/service/ServiceFour";
  import Contact from "../../views/Contact1";
  import ServiceFive from "../../components/service/ServiceFive";
  import ServiceSix from "../../components/service/ServiceSix";
  import ServiceContact from "../../components/service/ServiceContact";
  import ServiceContactPlus from "../../components/service/ServiceContactPlus";
  import Blog from "../../components/blog/Blog";
  import BrandChooseWindow from "../../components/brand/BrandChooseWindow";
  import Footer from "../../components/footer/FooterTwo";
  import GoogleMaps from "@/components/maps/GoogleMaps";

  export default {
    components: {
      HeaderTwo,
      SliderFour,
      GoogleMaps,
      ServiceFour,
      Contact,
      ServiceContact,
      ServiceContactPlus,
      ServiceFive,
      ServiceSix,
      Blog,
      Footer,
      BrandChooseWindow
    },
    data() {
      return {};
    },
  };
</script>
<style scoped>
h2 {
  font-family: "PFBagueSansPro-Bold";
  font-size: 40px !important;
}
.backgroung-grey{
  background-color: #e6e6e7;
}
@media only screen and (max-width: 1630px) {
  h2 {
    font-size: 40px !important;
  }
}
@media only screen and (max-width: 1350px) {
  h2 {
    font-size: 35px !important;
  }
}
@media only screen and (max-width: 1081px) {
  h2 {
    font-size: 22px !important;
  }
} 

.class-z-index {
    z-index: 0;
}


</style>