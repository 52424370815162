<template>
  <div class="row mt--30 mt_sm--0">
    <!-- Start Single Service  -->
    <v-col
      lg="2"
      md="4"
      sm="6"
      cols="12"
      class="mt_xl--70 mt_lg--40 mt_sm--30 mt_md--20"
      v-for="(serviceNext, j) in serviceContentNext"
      :key="j"
    >
      <div class="single-service large-size text-left">
        <div class="service">
          <!-- <div style="min-height: 100px">
            <img :src="serviceNext.src" alt="Corporate Images" />
          </div> -->
          <div v-if="serviceNext.id === 1">
            <a :href="serviceNext.site" target="_blank">
              <img
                slot="thum-img"
                width="180"
                src="../../assets/images/service/healthspot.png"
                alt="About Images"
            /></a>
          </div>
          <div v-else class="content">
            <p class="mt--12 boldParagraph">{{ serviceNext.address }}</p>
            <!-- <p v-bind:style="[serviceNext.id===3 ? {'max-width': '150px'} : {'max-width': auto}]">{{ serviceNext.number }}</p> -->
            <p>{{ serviceNext.number }}</p>
            <p>{{ serviceNext.phone }}</p>
            <p>{{ serviceNext.site }}</p>
          </div>
        </div>
      </div>
    </v-col>
    <!-- End Single Service  -->
  </div>
</template>

<script>
import feather from "feather-icons";
export default {
  data() {
    return {
      serviceContentNext: [
        {
          id: 1,
          src: require("../../assets/images/service/creta_clinic.png"),
          address: "Ερυθρού Σταυρού 4 & Κηφισίας",
          number: "151 23 Μαρούσι, Αθήνα",
          phone: "210 68 67 000",
          site: "https://www.hhg.gr/el/",
        },
        {
          id: 2,
          src: require("../../assets/images/service/litw.png"),
          address: "HealthSpot - Κηφισιάς",
          number: "Λεβίδου 16,",
          phone: "145 64 Κηφισιά",
          site: "212 80 86 100",
        },
        {
          id: 3,
          src: require("../../assets/images/service/litw.png"),
          address: "HealthSpot - Περιστερίου",
          // number: "Παναγή Τσαλδάρη & Σαρανταπόρου 1",
          number: "Παναγή Τσαλδάρη & Σαρανταπόρου 1",
          phone: "121 34 Περιστέρι",
          site: "212 8086200",
        },
        {
          id: 4,
          src: require("../../assets/images/service/metropolitan_gen.png"),
          address: "HealthSpot - Γλυφάδας",
          number: "Γρηγορίου Λαμπράκη 62",
          phone: "166 74 Γλυφάδα",
          site: "212 8086300",
        },
        {
          id: 5,
          src: require("../../assets/images/service/creta_clinic.png"),
          address: "HealthSpot - Πειραιά",
          number: "Ακτή Μιαούλη",
          phone: "185 38 Πειραιάς",
          site: "212 8086400",
        },
        {
          id: 6,
          src: require("../../assets/images/service/creta_clinic.png"),
          address: "HealthSpot - Σαντορίνη",
          number: "Μεσσαριά",
          phone: "847 00, Σαντορίνη",
          site: "212 8086500",
        },
        // {
        //   id: 6,
        //   src: require("../../assets/images/service/creta_clinic.png"),
        //   address: "HealthSpot - Ραφήνα",
        //   number: "Λιμάνι Ραφήνας",
        //   phone: "190 09 Ραφήνα",
        //   site: ""
        // }
      ],
    };
  },

  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.v-application p,
body p {
  color: #1d1d24;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 0px;
}
.boldParagraph {
  font-family: "PFBagueSansPro-Bold";
}
</style>
